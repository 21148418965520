<template>
  <main class="page">
    <section class="section section--auth c-register c-auth">
      <div class="auth-container">
        <form @submit="executeRecaptcha" novalidate>
          <div class="form__header mb-4">
            <div class="form__title">
              <h1 class="title title-lg">
                {{ $t('web.state.register') }}
              </h1>
            </div>
          </div>
          <div class="form__body">
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.EMAIL"
                  :label="$t('web.email')"
                  v-model="inputEmail"
                  autocomplete="on"
                  :type="CONSTANTS.INPUT.META.TYPE.EMAIL"
                  :validateMethods="validateMethods[CONSTANTS.LOCAL.REFS.EMAIL]"
                ></c-input>
              </div>
            </div>
            <div v-if="havePromo" class="form__group">
              <div class="form__item overflow-hidden">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.PROMO"
                  :label="$t('web.promo')"
                  v-model="inputPromo"
                  class="promocode promocode--active"
                  :type="CONSTANTS.INPUT.META.TYPE.TEXT"
                  :validateMethods="validateMethods[CONSTANTS.LOCAL.REFS.PROMO]"
                ></c-input>
              </div>
            </div>
            <div class="form__group">
              <div class="form__item overflow-hidden">
                <div class="i-have-promocode text-right">
                  <span
                    v-if="!havePromo"
                    @click="addPromoCode"
                    :title="$t('web.i-have-promocode')"
                  >
                    {{ $t('web.i-have-promocode') }}
                  </span>
                  <span
                    v-else
                    @click="removePromoCode"
                    :title="$t('web.i-dont-have-promocode')"
                  >
                    {{ $t('web.i-dont-have-promocode') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="form__group mt-3">
              <div class="form__item">
                <c-button
                  :value="$t('web.action.register')"
                  :loading="cButtonDisabled"
                ></c-button>
                <recaptcha ref="recaptcha" @verify="submitRegister"></recaptcha>
              </div>
            </div>
          </div>
          <div class="form__footer-new text-center mt-3">
            <div class="d-block text text-md">
              {{ $t('web.have_an_account') }} -
              <c-link
                :to="{ name: $ROUTER.NAME.AUTH.LOGIN }"
                nowrap
                :label="$t('signIn')"
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import METHODS from '@/utils/Validator/METHODS'
import * as CONSTANTS from '@/CONSTANTS'
import CInput from '@/components/Input'
import CButton from '@/components/Button'
import CLink from '@/components/Link'
import Recaptcha from '@/components/Recaptcha'
import { normalizeText } from '@/utils/text'
const CryptoJS = require('crypto-js')

const _CONSTANTS = {
  REFS: {
    EMAIL: 'email',
    PROMO: 'promo',
  },
}

export default {
  name: 'SignUp',

  components: {
    CInput,
    CButton,
    Recaptcha,
    CLink,
  },

  data() {
    return {
      cButtonDisabled: false,
      havePromo: false,
      inputEmail: '',
      inputPromo: '',
    }
  },

  computed: {
    CONSTANTS: {
      get() {
        return Object.assign({}, CONSTANTS, { LOCAL: _CONSTANTS })
      },
    },
    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.EMAIL]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.email')],
            METHODS.IS_EMAIL,
          ],
          [_CONSTANTS.REFS.PROMO]: [],
        }
      },
    },
  },

  methods: {
    executeRecaptcha(event) {
      event?.preventDefault()
      this.$refs.recaptcha.execute()
    },
    addPromoCode() {
      this.havePromo = true
    },
    removePromoCode() {
      this.havePromo = false
    },
    submitRegister() {
      this.register()
    },
    async storePassword(password) {
      const data = CryptoJS.AES.encrypt(
        password,
        this.$store.getters['config/pwdSecret']
      ).toString()
      localStorage.setItem('password', data)
    },
    register() {
      const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
        if (this.$refs[type]) {
          acc.push(this.$refs[type])
        }
        return acc
      }, [])
      const promises = inputs.map((input) => input.validate())
      Promise.all(promises)
        .then(() => {
          this.cButtonDisabled = true
          const promo = this.$refs[_CONSTANTS.REFS.PROMO]
            ? this.$refs[_CONSTANTS.REFS.PROMO].getValue()
            : null
          this.$api.auth
            .register(this.$refs[_CONSTANTS.REFS.EMAIL].getValue(), promo)
            .then((res) => {
              if (res.success === false) {
                this.$store.dispatch('clientServer/setSuccessStatus', {
                  key: this.CONSTANTS.API.STATUS.KEY.REGISTER,
                  value: res.status,
                })
              }
              this.storePassword(res.data.password)

              this.$store.dispatch('user/fetchUser').then(() => {
                this.$router.push({ name: this.$ROUTER.NAME.ACCOUNT.PANEL })
                this.$store.dispatch('app/setShowAfterRegistrationDialog', true)
              })
            })
            .catch((error) => {
              // Ужас. Нужно чтобы таких ифов не было
              if (error.errors.data) {
                this.$store.dispatch('app/showDialog', {
                  title: error.title,
                  type: 'error',
                  description: error.errors.data[0],
                })
              } else {
                const componentsMap = {
                  email: this.$refs[_CONSTANTS.REFS.EMAIL],
                  promocode: this.$refs[_CONSTANTS.REFS.PROMO],
                }
                Object.entries(error.errors).forEach((err) => {
                  componentsMap[err[0]].setError(normalizeText(err[1][0])) // Поработать с ошибками. Добавить валидатор
                })
              }
            })
            .finally(() => {
              this.cButtonDisabled = false
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
@import '../index';
.i-have-promocode {
  color: var(--main);
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
</style>
